import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import queryString from 'query-string';

import { MASTER_BROCHURE_FORM_CODE } from 'config/brochure-form';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import { BASE_URL } from 'config';

const ItineraryDownloadBackup = () => (
  <StaticQuery
    query={graphql`
      query {
        allTourPages(
          filter: {
                hidden_category: { eq: false },
              }
        ) {
          edges {
            node {
              id
              api_id
              name
              absolute_slug
              itinerary_form_code
            }
          }
        }
      }
    `}
    render={({ allTourPages }) => {
      let title;
      let subTitle;
      let formCode;

      const PARSED = typeof window === 'undefined' ? {} : queryString.parse(window.location.search);

      if (PARSED.tour_id === 'all') {
        title = 'Get your brochure';
        subTitle = 'Fill in a few details and we’ll send you our brochure.';
        formCode = MASTER_BROCHURE_FORM_CODE;
      } else {
        const data = FORMAT_GRAPH_DATA(allTourPages).find(item => item.api_id === PARSED.tour_id);
        if (data) {
          title = 'Get your itinerary';
          subTitle = `Fill in a few details and we’ll send you our ${data.name} itinerary.`;
          formCode = data.itinerary_form_code;
        }
      }

      if (!formCode) {
        return (
          <h1 className="c-heading c-heading--h2">Form data not found</h1>
        );
      }

      return (
        <Layout page="itinerary-download">
          <Helmet
            title="Download Itinerary"
            link={[{ rel: 'canonical', href: `${BASE_URL}/itinerary-download/` }]}
          />

          <div className="l-itinerary-download-form">
            <div className="l-container l-container--small">
              <div className="l-itinerary-download-form__header">
                <h1 className="c-heading c-heading--h2">{title}</h1>
                <p>{subTitle}</p>
              </div>
              <div
                className="l-itinerary-download-form__wrapper"
                dangerouslySetInnerHTML={{ __html: formCode }}
              />
            </div>
          </div>
        </Layout>
      );
    }}
  />
);

export default ItineraryDownloadBackup;
