/* eslint-disable */
const SALESFORCE_ORG_ID = process.env.GATSBY_SALESFORCE_ORG_ID;
const SALESFORCE_SITE_KEY = process.env.GATSBY_SALESFORCE_SITE_KEY;
const SALESFORCE_SITE_NAME = process.env.GATSBY_SALESFORCE_SITE_NAME;

export const MASTER_BROCHURE_FORM_CODE = `<form accept-charset="UTF-8" action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" class="infusion-form" method="POST">
    <input type=hidden name="oid" value="00D1y0000008jmd">
    <input type=hidden name="lead_source" value="Web - hakatours.com">
    <input type=hidden name="recordType" value="0122v000001q0nm">
    <input type=hidden name="00N2v00000SoEoJ" value="Sample Itinerary">
    <input type=hidden name="00N2v00000P4H1w" value="https://hakatours.com/itinerary-download/" />
    <input type="hidden" name="retURL" value="https://hakatours.com/thank-you/" />
    <input type="hidden" name="captcha_settings" value='{"keyname":${SALESFORCE_SITE_NAME},"fallback":"true","orgId":${SALESFORCE_ORG_ID},"ts":""}' />
    <div class="infusion-field">
      <label for="first_name">First Name *</label>
      <input required class="infusion-field-input" id="first_name" name="first_name" placeholder="First Name *" type="text" />
    </div>
    <div class="infusion-field">
      <label for="last_name">Last Name *</label>
      <input required class="infusion-field-input" id="last_name" name="last_name" placeholder="Last Name *" type="text" />
    </div>
    <div class="infusion-field">
      <label for="email">Email *</label>
      <input required class="infusion-field-input" id="email" name="email" placeholder="Email *" type="text" />
    </div>
    <div class="infusion-field">
      <label for="phone">Phone</label>
      <input class="infusion-field-input" id="phone" name="phone" placeholder="Please include the international dialing code" type="text" />
    </div>
    <div>
      <div>&nbsp;</div>
    </div>
    <div>
      <div>
        <div class="title">
          <div class="title" contentid="title">
            <div>
              <span>What type of tour are you interested in? Please select 1 option:</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>&nbsp;</div>
    </div>
    <div class="infusion-field">
      <label for="inf_option_Pleasetelluswhatkindofadventureyouwouldliketohave">Please tell us what kind of adventure you would like to have: *</label>
      <div class="infusion-radio">
        <div class="options-container">
          <span class="infusion-option">
            <input id="00N1y000000Hnp7" name="00N1y000000Hnp7" type="checkbox" />
            <label for="inf_option_Pleasetelluswhatkindofadventureyouwouldliketohave_1014">Adventure Tours</label>
          </span>
          <span class="infusion-option">
              <input id="00N1y000000HnpC" name="00N1y000000HnpC" type="checkbox" />
              <label for="inf_option_Pleasetelluswhatkindofadventureyouwouldliketohave_1016">Haka Plus</label>
          </span>
          <span class="infusion-option">
              <input id="00N1y000000HnpD" name="00N1y000000HnpD" type="checkbox" />
              <label for="inf_option_Pleasetelluswhatkindofadventureyouwouldliketohave_1018">Snow Tours</label>
          </span>
          <span class="infusion-option">
              <input id="00N1y000000HnpH" name="00N1y000000HnpH" type="checkbox" />
              <label for="inf_option_Pleasetelluswhatkindofadventureyouwouldliketohave_1020">Private Group Tours</label>
          </span>
        </div>
      </div>
    </div>
    <div class="infusion-field">
      <span class="infusion-option">
        <input checked="checked" id="00N2v00000P4H1x" name="00N2v00000P4H1x" type="checkbox" value="1" />
        <label for="inf_option_YesIwouldlovetolearnmoreaboutHakaToursSendmethebrochure">Yes, I would love to learn more about Haka Tours. Send me the brochure.</label>
      </span>
    </div>
    <div class="g-recaptcha" data-sitekey=${SALESFORCE_SITE_KEY}></div>
    <div>
        <div>&nbsp;</div>
    </div>
    <div class="infusion-submit">
        <button class="infusion-recaptcha" id="submit" name="submit" type="submit">Submit</button>
    </div>
</form>`;
